
import React  from 'react'
import $ from 'jquery'
// import Icore_Logo from './img/icore-software-name_white_woshadow.png'
// import './home.css';
import Bing_img from './img_new/header_logo/bing_badge.png'
import Analytics_img from './img_new/header_logo/analytics_adword1.png'
// import Adword_img from './img_new/header_logo/adword.png'
// import { BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
export default class Footer extends React.Component{
    componentDidMount=() =>{
      
$(document).ready(function(){ 
    $(window).scroll(function(){ 
        if ($(this).scrollTop() > 100) { 
            $('#scroll_footer_inner').fadeIn(); 
        } else { 
            $('#scroll_footer_inner').fadeOut(); 
        } 
    }); 
    $('#scroll_footer_inner').click(function(){ 
        $("html, body").animate({ scrollTop: 0 }, 3000); 
        return false; 
    }); 
});

var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/5c2ddf4b82491369baa04fb9/default';
s1.charset='UTF-8';
s1.setAttribute( 'crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
    }     
    render(){
        return(
            <div>
    <footer>
    <div className="container-fluid pad_cont_foot">
                        <div className="footer-grid">
                            <div className="footer-item">
                                <h5 className="footer_head_tag">Follow Us</h5>
                                <div className="social">
                                    <a href="https://www.facebook.com/iCoreSoftOfficial/" target="_blank" data-toggle="tooltip" title="Facebook"><i className="fab fa-facebook"></i></a>
                                    <a href="https://twitter.com/iCoreSoftOfficl" target="_blank" data-toggle="tooltip" title="Twitter"><i className="fab fa-twitter"></i></a>
                                    <a href="https://www.instagram.com/icoresoftofficial/" target="_blank" data-toggle="tooltip" title="Instagram"><i className="fab fa-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/icore-software-systems-private-ltd/" target="_blank" data-toggle="tooltip" title="LinkedIn"><i className="fab fa-linkedin"></i></a>
                                    <a href="https://wa.me/+917358056001" target="_blank" data-toggle="tooltip" title="WhatsApp"><i className="fab fa-whatsapp"></i></a>
                                    <a href="https://www.behance.net/icoresoftwar" target="_blank" data-toggle="tooltip" title="Behance"><i className="fab fa-behance"></i></a>
                                </div>
                            </div>

                            <div className="footer-item">
                                <h5 className="footer_head_tag">Accreditations</h5>
                                <ul>
                                    <li className="footer_li"><img alt="not found" src={Analytics_img} className="adword_img_wids" /></li>
                                    <li className="footer_li"><img alt="not found" src={Bing_img} className="adword_img_wids" /></li>
                                </ul>
                            </div>

                            <div className="footer-item">
                                <h5 className="footer_head_tag">INDIA</h5>
                                <ul>
                                    <li className="footer_li"><i className="fa fa-location-arrow icons_color_cont"></i>iCore Software Systems Private Limited</li>
                                    <li className="footer_li">5th floor, Gamma Block,</li>
                                    <li className="footer_li"> SSPDL Alpha city, OMR,</li>
                                    <li className="footer_li"> Navalur, Chennai-600130</li>
                                    <li className="footer_li"><i className="fa fa-phone icons_color_cont"></i><a className="txt_contact_underline" href="tel:+91 73580 56001"> +91 73580 56001</a></li>
                                    <li className="footer_li"><i className="fa fa-phone icons_color_cont"></i><a className="txt_contact_underline" href="tel:+91 44 42059777"> +91 44 4777 9801</a></li>
                                    <li className="footer_li"><i className="fa fa-envelope icons_color_cont"></i><a className="txt_contact_underline" href="mailto:contact@icoresoftwaresystems.com">contact@icoresoftwaresystems.com</a></li>
                                </ul>
                            </div>

                            <div className="footer-item">
                                <h5 className="footer_head_tag">USA</h5>
                                <ul>
                                    <li className="footer_li"><i className="fa fa-location-arrow icons_color_cont"></i>iCore Technologies, LLC</li>
                                    <li className="footer_li">12987 Allenwood Ave</li>
                                    <li className="footer_li">Frisco TX 75035</li>
                                    <li className="footer_li"><i className="fa fa-phone icons_color_cont"></i><a className="txt_contact_underline" href="tel:+14698852249"> +1 (469) 885-2249</a></li>
                                    <li className="footer_li"><i className="fa fa-envelope icons_color_cont"></i><a className="txt_contact_underline" href="mailto:contact@icoretek.com">contact@icoretek.com</a></li>
                                </ul>
                                <a className="atag_footer_float_rght_inner" id="scroll_footer_inner">
                                    <button className="button_footer_get_in_touch"><i className="fa fa-angle-double-up" aria-hidden="true"></i></button>
                                </a>
                            </div>
                        </div>
        <p className={ 'footer_copy_txt'}>&copy; {new Date().getFullYear()} iCore Software Systems Private Limited. All Rights Reserved.</p>
    </div>
</footer>
</div>
            )
        }
    }