
import React  from 'react'
import './portfolio.css'
// import AOS from 'aos'
import Extra_Videos from './extra_video.js'
import Web_Design from './img_new/videos/img1/ux1.png'
import Web_Development from './img_new/videos/img1/web-app1.png'
import Ecommerce_img from './img_new/videos/img1/ecommerce1.png'
import Mobile_img from './img_new/videos/img1/mobile app1.png'
import VR_img from './img_new/videos/img1/ar1.png'
import Digital_img from './img_new/videos/img1/seo1.png'
import { BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
export default class Our_capabilities extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          reality: {},
          mobile: {},
          application: {},
          ecommerce: {},
          design: {},
          digital: {}
        };
      }
    
      componentDidMount() {
        const reality = {
          title: "Augmented Reality / Virtual Reality",
          description: " At iCore Software Systems, we are at the forefront of technological innovation. Our team is dedicated to exploring and implementing advanced solutions that address complex business challenges. From AI-driven tools to blockchain applications, we provide forward-thinking solutions that keep your business ahead of the curve.",
        };
    
        const mobile = {
          title: "Mobile Apps development",
          description: "Our expertise in mobile app development ensures your business reaches customers anytime, anywhere. With a focus on intuitive design and seamless functionality, we create mobile applications that stand out in a crowded marketplace, delivering exceptional user experiences on both iOS and Android platforms.",
        };
    
        const application = {
          title: "Web Application Development",
          description: "At iCore Software Systems, we specialize in crafting high-performance web applications tailored to your business needs. Our team of seasoned developers leverages cutting-edge technologies to build scalable, secure, and user-friendly web solutions that drive growth and enhance your online presence.",
        };
    
        const ecommerce = {
          title: "E-commerce Solutions",
          description: " Unlock your local business's full potential with our highly advanced eCommerce solutions, which are designed to help you gain access to the enormous internet market and increase sales.",
        };
    
        const design = {
          title: "UI / UX Design",
          description: "We thrive at meticulously planning webpage layouts, creating engaging content, and designing eye-catching images, all while leveraging the latest technologies. Our considerable expertise as a web design firm, together with the long-term relationships we've developed with countless clients, speaks volumes about our capabilities.",
        };
    
        const digital = {
          title: "Digital Marketing",
          description: " Harness the power of digital marketing with our expert strategies designed to elevate your brand’s visibility and engagement. We offer comprehensive digital marketing services, including SEO, PPC, and social media management, to drive traffic, generate leads, and build lasting customer relationships.",
        };
    
        this.setState({ reality, mobile, application, ecommerce, design, digital });
      }   
    render(){
        const{ reality, mobile, application, ecommerce, design, digital } =this.state
        return(
            <div>
                <section className="bg_our_capa">
                <div className={'marg_all_heading'}>
                <h2 className="about_ser_new_tags">Recognized experts in...</h2>
                        <span className="about_bor_bot_serv"></span>
                        </div>
             <div class="container">
                 <div class="row our-service">
                 <div class="col-md-4">
                            <div class="service-box custom_hover">
                                <div class="service-img">
                                    <img data-aos="zoom-in" src={VR_img} alt="not found" />
                                </div>
                                <div class="service-content">
                                    <div class="service-title">
                                        <h4 data-aos="fade-up-right"><a >{reality.title}</a></h4>
                                    </div>
                                    <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{reality.description}</p>
                                         <Link to="/our-service/#AR"  target="_top"  class="box_btn">read more</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="service-box custom_hover">
                                <div class="service-img">
                                    <img data-aos="zoom-in" src={Mobile_img} alt="not found" />
                                </div>
                                <div class="service-content">
                                    <div class="service-title">
                                        <h4 data-aos="fade-up-right"><a >{mobile.title}</a></h4>
                                    </div>
                                    <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{mobile.description}</p>
                                        <Link to="/our-service/#Mobile" target="_top"  class="box_btn">read more</Link>
                                </div>
                            </div>
                        </div>
                     <div class="col-md-4">
                         <div class="service-box custom_hover">
                             <div class="service-img">
                                 <img data-aos="zoom-in" src={Web_Development} alt="not found" />
                             </div>
                             <div class="service-content">
                                 <div class="service-title">
                                     <h4 data-aos="fade-up-right"><a  >{application.title}</a></h4>
                                 </div>
                                 <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{application.description}</p>
                                     <Link to="/our-service/#webapps" target="_top" class="box_btn">read more</Link>
                             </div>
                         </div>
                     </div>
                     
                 </div>
                 <br></br><br></br>
                 <div class="row our-service ">
                 <div class="col-md-4">
                         <div class="service-box custom_hover">
                             <div class="service-img">
                                 <img data-aos="zoom-in" src={Ecommerce_img} alt="not found" />
                             </div>
                             <div class="service-content">
                                 <div class="service-title">
                                     <h4 data-aos="fade-up-right"><a >{ecommerce.title}</a></h4>
                                 </div>
                                 <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{ecommerce.description}</p>
                                     <Link to="/our-service/#Ecommerce" target="_top"  class="box_btn">read more</Link>
                             </div>
                         </div>
                     </div>
                 <div class="col-md-4">
                         <div class="service-box custom_hover">
                             <div class="service-img">
                                 <img data-aos="zoom-in" src={Web_Design} alt="not found" />
                             </div>
                             <div class="service-content">
                                 <div class="service-title">
                                     <h4><a  data-aos="fade-up-right">{design.title}</a></h4>
                                 </div>
                                 <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{design.description}</p>
                                     <Link to="/our-service/#webdesign" target="_top"  class="box_btn">read more</Link>
                             </div>
                         </div>
                     </div>
                        <div class="col-md-4">
                            <div class="service-box custom_hover">
                                <div class="service-img">
                                    <img data-aos="zoom-in" src={Digital_img} alt="not found" />
                                </div>
                                <div class="service-content">
                                    <div class="service-title">
                                        <h4 data-aos="fade-up-right"><a >{digital.title}</a></h4>
                                    </div>
                                    <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{digital.description}</p>
                                        <Link to="/our-service/#SEO"  target="_top"  class="box_btn">read more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
             </div> 
    </section>   
            </div>
            )
        }
    }