import React from 'react'
import './about_new_two_img.css'
// import Tic_png from './img/cropped-favicon.png'
//  import Running_Header from './running_header'
export default class About_new_two_img extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
         description:{}
        };
      }
      componentDidMount=() =>{
     const description = {
        description: "We are a technology-driven web and mobile app development company dedicated to providing solutions that are tailored to meet the unique needs of our clients. Our comprehensive service offerings are designed to empower businesses, enabling them to stay agile and competitive in today’s rapidly evolving market. Leveraging our expertise in digital transformation, we focus on streamlining enterprise-level processes to enhance efficiency and reduce operational costs. Our approach ensures seamless business operations, paving the way for sustained growth and increased revenue. Partner with us to elevate your digital capabilities and achieve your strategic objectives."
     }
   this.setState({ description });
}     
   
  render(){
    const { description }= this.state
    return(
         <div>
        {/* technologies */}
        <div className={'row'}>
        {/* <div className="col-lg-1"></div> */}
        <div className={'bg_technology col-lg-12'}>
      <div className={'technology_card row'}>
         <div className="col-lg-1"></div>
         <div className={'fnt_si_tehno col-lg-1 reactfnt_si_tehno'}><i title="React" className='fab fa-react'></i></div>
         <div className={'fnt_si_tehno col-lg-1 angularfnt_si_tehno'}><i title="Angular" className='fab fa-angular'></i></div>
         <div className={'fnt_si_tehno col-lg-1 nodejs_clr'}><i title="Node-js" className='fab fa-node-js'></i></div>
         <div className={'fnt_si_tehno col-lg-1 java_clr'}><i title="Java" className='fab fa-java'></i></div>
         <div className={'fnt_si_tehno col-lg-1 html5_clr'}><i title="HTML5" className='fab fa-html5'></i></div>
         <div className={'fnt_si_tehno col-lg-1 css3_clr'}><i title="CSS3" className='fab fa-css3-alt'></i></div>
         <div className={'fnt_si_tehno col-lg-1 wordpress_clr'}><i title="Wordpress" className='fab fa-wordpress-simple'></i></div>
         <div className={'fnt_si_tehno col-lg-1 php_clr'}><i title="PHP" className='fab fa-php'></i></div>
         <div className={'fnt_si_tehno col-lg-1 joomla_clr'}><i title="Joomla" className='fab fa-joomla'></i></div>
         <div className={'fnt_si_tehno col-lg-1 drupal_clr'}><i title="Drupal" className="fab fa-drupal" aria-hidden="true"></i></div>
         <div className="col-lg-1"></div>
      </div>
   </div>
        {/* <div className="col-lg-1"></div> */}
        </div>
    <div className="" >
        <div className="row">
            <div className="col-lg-12">
                <div className={'marg_all_heading'}>
         <h2 className="about_ser_new_tags">Who we are ?</h2>
                        <span className="about_bor_bot_serv"></span>
                        </div>
                    <p className="p_tag_new_about" data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">
                        {description.description}
                 </p>
            </div>
        </div>
    </div>
    
</div>

    )
  }
}