import React, { Component } from 'react';
import './App.css';
import './css/plugins/helper.css';
import './style_new.scss';
import Home from './home.js';
import Header from './header.js'

// import Running_Header from './running_header'
// import About from './about.js'
// import Our_work from './running_header/our_work_running.js'
// import Our_service_runnning from './running_header/our_service_running.js'
// import Our_work_running from './running_header/our_work_running.js'
// import About_Phone_header from './running_header/about_phone_head.js'
//  import Loading from './loader_new.js'
// import Slider from './slider.js'
import Swiper from './swiper_slider.js'
import Footer from './footer.js'
// import Slider_ss from './sliding1.js'
import Testimonial from './testimonial.js'
// import Our_work_home from './our_work' 
// import Contactus from './contact.js'
// import Client_Slider from './client_slider.js'
import Ourclient from './our_client.js'
// import Port_New from './loader/new_portfolio.js'
import Port from './extra_port.js'
import About_Phone from './about_phone.js'
// import Color from './color_bg.js';
// import Extra_Videos from './extra_video.js'
// import Portfolio_Mobile from './portfolio_mobile.js'
// import Extra_Video from './extra_video.js'
// import Color_mobile_responsive from './color_bg_mobile_responsive.js'
import About_new_two_img from './about_new_two_img.js'
// import Animation_Text from './animation_text.js'
// import Slide from './slider.js'
// import Rotation_work from './about_us_pages/rotation_work.js'
// import About_Us_Inner from './about_us_pages/about_us_inner.js'
// import About_inner_head_video from './about_us_pages/about_inner_head_video1.js'
// import Our_Service_Inner from './our_service_page/our_service_inner.js'
// import Popup_contact from './contact_us_page/thankyou.js'
// import $ from 'jquery'
// import Our_solition from './our_solution_page/our_solution.js'
// import Our_solution_Connect from './our-solutions.js'
// import Blogs from './blogs.js'
// import Web_design_landing from './web_design_service.js'
// import Web_design_service from './web_design_more.js'
import Our_capabilities from './our_capabilities.js'
// import Career from './careers'
class App extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  

  render() {
    return (
      <div>
       <Header/>
        <Home/>
        {/* <Running_Header/> */}
        <About_new_two_img/> 
        {/* <About_Phone_header/>  */}
        <About_Phone/>
        {/* <Extra_Videos/> */}
        <Our_capabilities/>
        {/* <Our_work_running/> */}
        <Port/>
        {/* <Team/> */} 
        <Swiper/>
        <Testimonial/> 
        <Ourclient/>
        {/* <Contactus/>  */}
        <Footer/> 
        {/* <Slider_ss/> */}
        {/* <Slider/> */}
        {/* <Client_Slider/> */}
        {/* <Extra_Video/> */}
          {/* 
        <About/>
        // */}
        {/* <Landing_Page/> */}
          {/* <Color/> */}
          {/* <Popup_contact/> */}
          {/* <Header/> */}
          {/* <About_Us_Header_Video/> */}
          {/* <About_Us_Inner/> */}
          {/* <Our_Service_Inner/> */}
          {/* <About_inner_head_video/> */}
          {/* <Rotation_work/> */}
          
          {/* <Color_mobile_responsive/> */}
          {/* <Portfolio_Mobile/> */}
         
          
          {/* <Port_New/> */}
         
        {/* <Portfolio/> */}
        {/* <Running_Header/> */}
        {/* <Our_work_home/> */}
        {/* <About/> */}
        
        {/* <Our_work/> */}
        {/* <Our_service_runnning/> */}
       
       
           {/* <Loading/>    */}
          {/* <Our_solition/> */}
         {/* <Our_solution_Connect/> */}
          {/* <Phone_number/> */}
          {/* <Animation_Text/> */}
         {/* <Slide/> */}
         
         
      </div>
    );
  }
}

export default App;
