import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/jquery/dist/jquery.min.js';
// import '../node_modules/get-google-fonts/fonts/fonts.css';
// import '../node_modules/aos/dist/aos.css';
// import '../node_modules/aos/dist/aos.js';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './css/fontawesome-free-5.11.2-web/css/all.css'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Routes, Route,Link } from 'react-router-dom';
import Header from './header.js'
import Color from './color_bg.js';
import About_Us_Inner from './about_us_pages/about_us_inner.js'
import Our_Service_Inner from './our_service_page/our_service_inner.js'
import Our_Service_Inner_Connect from './our-service.js'
import About_Us from './about-us.js'
import Our_work_Inner_Connect from './our-work.js'
import Contact_us_inner_connect from './contact-us.js'
// import Loading_contact from './loader_new_contact.js'
// import Loading_about from './loading_new_about.js'
// import Loading_our_work from './loading_new_our_work.js'
// import Loading_our_service from './loading_new_our_service.js'
import Popup_contact from './contact_us_page/thankyou.js'
// import Our_solution_Connect from './our-solutions.js'
// import IOT from './our_solution_page/iot.js'
// import Our_Solution_Cloud from './our_solution_page/cloud_service.js'
// import Our_solution_Text from './our_solution_page/our_solution.js'
// import Devops from './our_solution_page/devops.js'
import PageNotFound from './notfoundpage.js'
import Blogs from './blogs.js'
import Blogs1 from './blogs1.js'
import Blogs2 from './blogs2.js'
import Blogs3 from './blogs3.js'
import Blogs4 from './blogs4.js'
import Blogs5 from './blogs5.js'
import Blogs6 from './blogs6.js'
import Blogs7 from './blogs7.js'
import Blogs8 from './blogs8.js'
import Blogs9 from './blogs9'
import Blogsten from './blogs10'
import Blogs11 from './blogs11.js'
import Blogs12 from './blogs12.js'
// import Web_design_landing from './web_design_service.js'
// import Web_design_service from './web_design_more.js'
// import Hello from './cookies.js';
import { CookiesProvider } from "react-cookie";
import Webdesign_and_developments from './our_service_drop/web-design'
import Mobile_and_developments from './our_service_drop/mobile_apps_drop'
import Ourniche_and_developments from './our_service_drop/our_niche'
import DigitalAndDevelopments from './our_service_drop/digital_mark'
import Mobile_and_Aus from './our_service_drop/mobile_aus'
import Web_and_Aus from './our_service_drop/web_aus'
import Career from './careers.js'
import { render } from "react-dom";
import Web_developement_landing from './landing_page/web_developement'
import Mobile_app_Development from './landing_page/mobile_development'
import Blogs13 from './blogs13.js';
import Blogs14 from './blogs14.js';
import CommonDevelopmentMistakes from './common-developement-mistake-blogs.js';

render(
  
  (<Router basename={process.env.REACT_APP_ROUTER_BASE || ''}>   
     {/*basename={process.env.REACT_APP_ROUTER_BASE || ''}  */}
    
        <Routes>
            {/* <Route path="/" element={<App cookies={this.props.cookies} />} /> */}
            <Route exact path='/' element={<App />} />
            <Route path="/header" element={<Header />} />
            <Route path="/about-Us" element={<About_Us />} />
            <Route path="/color_bg" element={<Color />} />
            <Route path="/our-work" element={<Our_work_Inner_Connect />} />
            <Route path="/our-service" element={<Our_Service_Inner_Connect />} />
            <Route path="/contact-us" element={<Contact_us_inner_connect />} />
            <Route path="/thankyou" element={<Popup_contact />} />
            <Route path="/blogs" element={<Blogs />} />            
            <Route path="/blogs/web-application-development-using-java" element={<Blogs1 />} />
            <Route path="/blogs/mobile-application-development-using-ionic-framework" element={<Blogs2 />} />
            <Route path="/blogs/definition-of-augmented-reality-and-how-does-it-work" element={<Blogs3 />} />
            <Route path="/blogs/how-can-the-best-UX-UI-designs-influence-your-business" element={<Blogs4 />} />
            <Route path="/blogs/how-a-taxi-booking-app-can-improve-your-business" element={<Blogs5 />} />
            <Route path="/blogs/the-definition-of-online-reputation-management" element={<Blogs6 />} />
            <Route path="/blogs/differences-between-UX-and-UI-design" element={<Blogs7 />} />
            <Route path="/blogs/how-to-preserve-your-brand-online-in-2020-with-reputation-management-software" element={<Blogs8 />} />
            <Route path="/blogs/the-most-trending-technology-stacks-of-the-year" element={<Blogs9 />} />
            <Route path="/blogs/the-importance-of-quality-assurance-and-testing-in-software-development" element={<Blogsten />} />
            <Route path="/blogs/E-Commerce-Augmented-Reality-App-in-Texas" element={<Blogs11 />} />
            <Route path="/blogs/how-to-protect-WordPress-website-from-hackers" element={<Blogs12 />} />
            <Route path="/blogs/how-to-make-your-WordPress-URL-SEO-Friendly" element={<Blogs13 />} />
            <Route path="/blogs/10-Ways-to-Speed-up-WordPress-Performance" element={<Blogs14 />} />
            <Route path="/web-application-development-company" element={<Webdesign_and_developments />} />
            <Route path="/mobile-application-development-company" element={<Mobile_and_developments />} />
            <Route path="/our-niche" element={<Ourniche_and_developments />} />
            <Route path="/digital-marketing" element={<DigitalAndDevelopments/>} />
            <Route path="/careers" element={<Career />} />
            <Route path="/web-application-development" element={<Web_developement_landing />} />
            <Route path="/mobile-application-development" element={<Mobile_app_Development />} />
            <Route path="/development-commom-mistakes" element={<CommonDevelopmentMistakes />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
</Router>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
