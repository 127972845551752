import React from 'react';
import { Link } from 'react-router-dom';
import Header from './header.js'
import Footer from './footer.js'
import Not_found from './img_new/not_found.png';
const NotFound = () => (
<div>
<Header/>
<img src={Not_found} alt="not found" />
<center><Link to="/" className="not_found_backto">Back to Home Page</Link></center>
<Footer/>
</div>
);
export default NotFound;