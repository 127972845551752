import React  from 'react'

import './about_phone.css';
import Round_img_about from './img_new/about_phone/cir_new.png'
import Logo_img_about from './img_new/about_phone/about_bg_logo1.png'
export default class About_Phone extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          creative: {},
          customerFocused: {},
          futuristic: {},
          reliable: {},
          strategic: {},
          committed: {}
        };
      }
    
      componentDidMount() {
        const creative = {
          title: "Superior Creativity",
          description: "We strive for excellence in order to provide you with high-quality, unique items that meet your demands.",
        };
    
        const customerFocused = {
          title: "Customer-Centric Focus",
          description: "Our deep commitment to the understanding and meeting client needs has earned us significant trust and respect.",
        };
    
        const futuristic = {
          title: "Forward-Thinking Approach",
          description: "We anticipate industry trends to provide future-ready solutions for your challenges.",
        };
    
        const reliable = {
          title: "Proven Reliability",
          description: "Our track record of dependable service means you can trust us to handle all your technology needs.",
        };
    
        const strategic = {
          title: "Strategic Solutions",
          description: "We leverage a data-driven approach to problem-solving, ensuring tailored solutions for every client. Our focus is on delivering innovative, high-quality results.",
        };
    
        const committed = {
          title: "Dedicated Commitment",
          description: "We focus on precise requirements gathering to deliver customized solutions that meet your exact needs.",
        };
    
        this.setState({ creative, customerFocused, futuristic, reliable, strategic, committed });
      }
    

     
    render(){
        const { creative, customerFocused, futuristic, reliable, strategic, committed } = this.state;
        return(    
            <div>
                <div className={'marg_all_heading'}>
                  <h2 className="about_ser_new_tags">Make your brand better</h2>
                        <span className="about_bor_bot_serv"></span>
                        </div>
                <div className="container-fluid  pad_3per_recre mrg_t2per_rechan">
                    <div className="resp-brand-screen">
                        <div className="col-lg-4 custom-resp-brand">
                        <div className="grid-container">
                            <div className="grid-item">
                                <div className="txt_left_about_phone scrollbar_two" id="style-4">
                                    <h4 className="about_phone_headtag" data-aos="fade-right">{creative.title}</h4>
                                    <span className="about_phone_ptag force-overflow" data-aos="fade-right">{creative.description}</span>
                                </div>
                                <div className="brand-icons">
                                <i data-aos="fade-right" className="fa fa-info fontawesome-leaf icons_btn_about_phone_img icn_about_phone"></i>
                                </div>
                            </div>

                            <div className="grid-item">
                                <div className="txt_left_about_phone scrollbar_two" id="style-4">
                                    <h4 className="about_phone_headtag" data-aos="fade-right">{customerFocused.title}</h4>
                                    <span className="about_phone_ptag" data-aos="fade-right">{customerFocused.description}</span>
                                </div>
                                <div className="brand-icons">
                                <i data-aos="fade-right" className="fa fa-info fontawesome-leaf icons_btn_about_phone_img icn_about_phone"></i>
                                </div>
                            </div>

                            <div className="grid-item">
                                <div className="txt_left_about_phone scrollbar_two" id="style-4">
                                    <h4 className="about_phone_headtag" data-aos="fade-right">{futuristic.title}</h4>
                                    <p className="about_phone_ptag force-overflow" data-aos="fade-right">{futuristic.description}</p>
                                </div>
                                <div className="brand-icons">
                                <i data-aos="fade-right" className="fa fa-desktop fontawesome-leaf icons_btn_about_phone_img icn_about_phone"></i>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 mgt_69per mrg_btm_mobile">
                            <div className="wid_circle_img">
                        {/* <div className="bg_icore_logo_about_phone "> */}
                        <img src={Round_img_about} className="mt_top_rotating_img" id="loading" alt="not found"/>
                           <img src={Logo_img_about} className="phone_img_about bg_back_animation_about " alt="not found"/>
                             </div></div>
                             {/* <div className="col-lg-1"></div> */}
                            <div className="col-lg-4">
                                <div className='grid-container resp-grid-container'>
                            <div className="grid-item">
                            <div className="left-brand-icons">
                            <i data-aos="fade-left" className="fa fa-thumbs-up fontawesome-leaf icons_btn_about_phone_img icn_about_phone_right"></i>
                            </div>
                            <div className="txt_left_about_phone_right scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag " data-aos="fade-left">{reliable.title}</h4>
                            <p className="about_phone_ptag" data-aos="fade-left">{reliable.description}</p>
                            <span></span>
                            </div>
                        </div>
                        {/* second icons */}
                        <div className="grid-item">
                            <div className="left-brand-icons">
                            <i data-aos="fade-left" className="fa fa-users fontawesome-leaf icons_btn_about_phone_img icn_about_phone_right"></i>
                            </div>
                            <div className="txt_left_about_phone_right scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag" data-aos="fade-right">{strategic.title}</h4>
                            <p className="about_phone_ptag" data-aos="fade-right">{strategic.description}</p>                        
                            <span></span>
                            </div>
                        </div>
                        {/* third icons */}
                        <div className="grid-item">
                            <div className="left-brand-icons">
                            <i data-aos="fade-left" className="fa fa-handshake-o fontawesome-leaf icons_btn_about_phone_img icn_about_phone_right"></i>
                            </div>
                            <div className="txt_left_about_phone_right scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag" data-aos="fade-left">{committed.title}</h4>
                            <p className="about_phone_ptag" data-aos="fade-left">{committed.description}</p>
                            <span></span>
                            </div>                          
                        </div>
                        </div>
                            </div>
                    </div>
                </div>


                {/* for mobile responsive */}

                
                {/* <div className="container-fluid disable_web_media_about mt_mobile_tab">
                    <div className="">
                        <div className="col-lg-4">
                        <div className="">
                            <div className="col-lg-10">
                            <div className="txt_left_about_phone scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag" data-aos="fade-right"> Creative</h4>
                            <p className="about_phone_ptag" data-aos="fade-right">With constant exploration of our creativity, we have built products and delivered them to clients with ample value addition. We make it a point to extend our creative boundaries continuously which 
                            enables us to deliver creative solutions ahead of time</p>
                            <span></span>
                            </div>
                            </div>
                            <div className="col-lg-2">
                          
                            <i data-aos="fade-right" className="fa fa-lightbulb-o fontawesome-leaf icons_btn_about_phone_img icn_about_phone"></i>
                            </div>
                        </div>
                        
                        <div className="">
                            <div className="col-lg-10">
                            <div className="txt_left_about_phone scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag" data-aos="fade-right"> Strategic</h4>
                            <p className="about_phone_ptag" data-aos="fade-right">Our strategic problem-solving approach with deep analysis results in high end solution development for our clients. iCore follows the best industry practices and development strategies in project planning 
                            and execution which favours scalable service delivery</p>
                            <span></span>
                            </div>
                            </div>
                            <div className="col-lg-2">
                          
                            <i data-aos="fade-right" className="fa fa-info fontawesome-leaf icons_btn_about_phone_img icn_about_phone"></i>
                            </div>
                        </div>
                       
                        <div className="">
                            <div className="col-lg-10">
                            <div className="txt_left_about_phone scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag" data-aos="fade-right"> Futuristic</h4>
                            <p className="about_phone_ptag" data-aos="fade-right">Our ability to foresee the future trends and developments in the industry is imbibed in our work culture. Our futuristic approach in analyzing and solving our clients’.</p>
                            <span></span>
                            </div>
                            </div>
                            <div className="col-lg-2">
                            <i data-aos="fade-right" className="fa fa-desktop fontawesome-leaf icons_btn_about_phone_img icn_about_phone"></i>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 mgt_69per mrg_btm_mobile mb_bot_img_about ">
                        
                        <div className="wid_circle_img">
                        <img src={Round_img_about} className="" id="loading" alt="not found"/>

                           <img src={Logo_img_about} className=" phone_img_about bg_back_animation_about" alt="not found" />
                             </div></div>
                            <div className="col-lg-4">
                            <div className="">
                            <div className="col-lg-2 mb_bot_all_about">
                          
                            <i data-aos="fade-right" className="fa fa-thumbs-up fontawesome-leaf  icons_btn_about_phone_img icn_about_phone_right"></i>
                            </div>
                            <div className="col-lg-10 ">
                            <div className="txt_left_about_phone_right scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag fnt_mob" data-aos="fade-right"> Reliable</h4>
                            <p className="about_phone_ptag" data-aos="fade-right">Our working approach with our clients in past engagements has earned a reputation of reliability 
                            and our clients rely on us to cross all technology related hurdles</p>
                            <span></span>
                            </div>
                            </div>
                        </div>
                        
                        <div className="">
                            <div className="col-lg-2 mb_bot_all_about">
                            <i data-aos="fade-right" className="fa fa-users fontawesome-leaf  icons_btn_about_phone_img icn_about_phone_right"></i>
                            </div>
                            <div className="col-lg-10">
                            <div className="txt_left_about_phone_right scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag" data-aos="fade-right"> Customer Focused  </h4>
                            <p className="about_phone_ptag" data-aos="fade-right">Strong customer focus, building customer centric solutions and our confidence in execution of our business has 
                            earned us immense trust and respect from our clients</p>
                            <span></span>
                            </div>
                            </div>
                        </div>
                      
                        <div className="">
                            <div className="col-lg-2 mb_bot_all_about">
                            <i data-aos="fade-right" className="fa fa-handshake-o fontawesome-leaf  icons_btn_about_phone_img icn_about_phone_right"></i>
                            </div>
                            <div className="col-lg-10">
                            <div className="txt_left_about_phone_right scrollbar_two" id="style-4">
                            <h4 className="about_phone_headtag" data-aos="fade-right">Committed</h4>
                            <p className="about_phone_ptag" data-aos="fade-right">Our team is committed towards providing tailor-made solutions by accurate requirements gathering to meet client requirements and exceeding their 
                            expectations in all aspects of our service delivery</p>
                            <span></span>
                            </div>
                            </div>
                        </div>
                            </div>
                    </div>
                </div> */}

             </div>
        )
    }
}